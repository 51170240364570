import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  IconButton,
  Input,
  Select,
  useToast,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../axios";
import Cookies from "js-cookie";
import OrderDetail from "./OrderDetail";

const OrderTable = ({ data, headerData, isLoading, setTrigger }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("orderTable -->", data);

  const submitHandler = () => {
    if (invoiceId) {
      setIsDisable(true);
      axios
        .put(
          "/api/invoice/",
          {
            invoiceId: invoiceId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          //   console.log(result);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setModalShow(false)
          setTrigger(true);
        })
        .catch((err) => {
          //   console.log(err.response);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const deleteHandler = () => {
    if (invoiceId) {
      setIsDisable(true);
      axios
        .delete("/api/invoice/", {
          data: {
            invoiceId: invoiceId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          //   console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          onClose();
          setInvoiceId("");
          setTrigger(true);
          setIsDisable(false);
        })
        .catch((err) => {
          //   console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead>
              <Tr bg={"#F6F6F6"}>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Нэхэмжлэлийн дугаар ------------ */}
                    {e.invoiceNo ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.invoiceNo}
                        </Text>
                      </Td>
                    ) : null}

                    {/* ------------ Дэлгэрэнгүй ------------ */}
                    {e.products ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <OrderDetail data={e} />
                      </Td>
                    ) : null}
                    {/* ------------ Үнэ ------------ */}
                    {e.totalPrice ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.totalPrice.toLocaleString() + "₮"}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Огноо ------------ */}
                    {e.createdAt ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.createdAt}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Төлөв ------------ */}
                    {e.status ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"medium"}
                          color={
                            e.status === "pending"
                              ? "#000"
                              : e.status === "paid"
                              ? "green"
                              : "#ff0000"
                          }
                        >
                          {e.status === "pending"
                            ? "Хүлээгдэж буй"
                            : e.status === "paid"
                            ? "Төлөгдсөн"
                            : "Цуцлагдсан"}
                        </Text>
                      </Td>
                    ) : null}

                    <Td w={"20"}>
                      <HStack>
                        <IconButton
                          //   isLoading={isDisable}
                          variant="solid"
                          size={"sm"}
                          colorScheme="green"
                          aria-label="Done"
                          fontSize="15"
                          icon={<Icon as={IoMdCheckmark} />}
                          onClick={() => {
                            setModalShow(true);
                            setInvoiceId(e._id);
                          }}
                          disabled={
                            !isDisable && e.status === "pending" ? false : true
                          }
                          _focus={{
                            outline: "none",
                          }}
                        />
                        <IconButton
                          variant="solid"
                          size={"sm"}
                          colorScheme="red"
                          aria-label="Close"
                          fontSize="15"
                          icon={<Icon as={IoMdClose} />}
                          disabled={
                            !isDisable && e.status === "pending" ? false : true
                          }
                          onClick={() => {
                            onOpen();
                            setInvoiceId(e._id);
                          }}
                          _focus={{
                            outline: "none",
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <Modal
        isCentered
        onClose={!modalShow}
        isOpen={modalShow}
        motionPreset="scale"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"}>
          <ModalHeader fontSize={17}>Нэхэмжлэл төлсөн болгох</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => {
              setModalShow(false);
              setInvoiceId("");
            }}
          />
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                setModalShow(false);
                setInvoiceId("");
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="green"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              disabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="scale"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"}>
          <ModalHeader fontSize={17}>Нэхэмжлэлийг цуцлах уу?</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => {
              onClose();
              setInvoiceId("");
            }}
          />
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setInvoiceId("");
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default OrderTable;
