import React from "react";
import { Input, Text, VStack } from "@chakra-ui/react";

const CustomInput = ({ title, value, setData, onKeyPress, type }) => {
  return (
    <VStack w={"full"} align="flex-start" justify={"flex-start"} spacing={0}>
      <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
        {title + ":"}
      </Text>
      <Input
        placeholder="-"
        w={"full"}
        // size={"sm"}
        rounded={"none"}
        fontWeight={"normal"}
        fontSize={13}
        color={"#000"}
        type={type ? "number" : "text"}
        value={value}
        onChange={(e) => setData(e.target.value)}
        _focus={{
          outline: "none",
          borderColor: "#2EAA2E",
        }}
        _hover={{
          outline: "none",
        }}
        onKeyPress={onKeyPress}
      />
    </VStack>
  );
};

export default CustomInput;
