import React, { useEffect, useState } from "react";
import { Text, useToast, VStack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "../../lang";
import OrderTable from "./OrderTable";

const Order = () => {
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (trigger) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/invoice/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Order --------->", result.data);
          setData(result.data.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.totalInvoices));
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Нэхэмжлэлийн дугаар",
    "Дэлгэрэнгүй",
    "Үнэ",
    "Огноо",
    "Төлөв",
    "Үйлдэл",
  ];

  const changePage = (current, pageSize) => {
    // console.log("onChange:current=", current);
    setCurrentPage(current);
    setTrigger(true);
  };

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
      {/* <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
        Захиалга
      </Text> */}
      <VStack w={"full"} pb={"6"} spacing={4}>
        <OrderTable
          data={data}
          headerData={headerData}
          isLoading={isLoading}
          setTrigger={setTrigger}
        />
        <Pagination
          // showQuickJumper
          pageSize={30}
          current={currentPage}
          onChange={changePage}
          total={totalCount}
          locale={localeInfo}
          style={{
            alignSelf: "flex-end",
          }}
        />
      </VStack>
    </VStack>
  );
};

export default Order;
