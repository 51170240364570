import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  IconButton,
  Input,
  Select,
  useToast,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../axios";
import Cookies from "js-cookie";
import OrderDetail from "./OrderDetail";

const DetailTable = ({ data, headerData, isLoading, setTrigger }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("DetailTable -->", data);

  return (
    <TableContainer w={"full"} pos={"relative"}>
      <Table border={"1px"} borderColor={"#ECECEC"} size={"sm"}>
        {headerData ? (
          <Thead>
            <Tr bg={"#F6F6F6"}>
              {headerData.map((e, i) => (
                <Th
                  key={i}
                  borderRight={"1px"}
                  borderRightColor={"#ECECEC"}
                  fontSize={13}
                  fontWeight={"medium"}
                  textTransform={"initial"}
                >
                  {e}
                </Th>
              ))}
            </Tr>
          </Thead>
        ) : null}
        {data.length > 0
          ? data.map((e, i) => (
              <Tbody key={i} bg={"#fff"}>
                <Tr w={"full"}>
                  <Td
                    w={"6"}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    align={"center"}
                    justify={"flex-start"}
                    color={"#000"}
                    fontWeight={"normal"}
                    fontSize={13}
                  >
                    <Text>{i + 1}</Text>
                  </Td>
                  {/* ------------ Нэр ------------ */}
                  {e.productName ? (
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      maxW={"52"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.productName}
                      </Text>
                    </Td>
                  ) : null}

                  {/* ------------ Үнэ ------------ */}
                  {e.priceEach ? (
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.priceEach.toLocaleString() + "₮"}
                      </Text>
                    </Td>
                  ) : null}
                  {/* ------------ Тоо ширхэг ------------ */}
                  {e.amount ? (
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.amount + "ш"}
                      </Text>
                    </Td>
                  ) : null}
                  {/* ------------ Нийт үнэ ------------ */}
                  {e.totalPrice ? (
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.totalPrice.toLocaleString() + "₮"}
                      </Text>
                    </Td>
                  ) : null}
                </Tr>
              </Tbody>
            ))
          : null}
      </Table>
    </TableContainer>
  );
};

export default DetailTable;
