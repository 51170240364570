import React, { useEffect, useState } from "react";
import { HStack, Icon, Text, useToast, VStack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../axios";
import { BiPlus } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import ProductTable from "./ProductTable";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "../../lang";

const Product = () => {
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (trigger) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/product", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Product --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Зураг",
    "Нэр",
    "Дэлгэрэнгүй",
    "Ангилал",
    "Үнэ",
    "Үйлдэл",
  ];

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <HStack>
        <HStack
          bg={"#fff"}
          px={"8"}
          py={"2"}
          border={"1px"}
          borderColor={"#ddd"}
          onClick={() => history.push("/product/add")}
          cursor={"pointer"}
        >
          <Icon as={BiPlus} />
          <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
            Бүтээгдэхүүн нэмэх
          </Text>
        </HStack>

        <HStack
          bg={"#fff"}
          px={"8"}
          py={"2"}
          border={"1px"}
          borderColor={"#ddd"}
          onClick={() => history.push("/product/banner")}
          cursor={"pointer"}
        >
          <Icon as={BiPlus} />
          <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
            Зар сурталчилгаа нэмэх
          </Text>
        </HStack>
      </HStack>
      <VStack w={"full"} pb={"6"} spacing={4}>
        <ProductTable
          data={data}
          headerData={headerData}
          isLoading={isLoading}
          setTrigger={setTrigger}
        />
        {/* <Pagination
          // showQuickJumper
          pageSize={30}
          current={currentPage}
          onChange={changePage}
          total={totalCount}
          locale={localeInfo}
          style={{
            alignSelf: "flex-end",
          }}
        /> */}
      </VStack>
    </VStack>
  );
};

export default Product;
