import {
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { navData } from "./SidebarData";
import UserContext from "../../context/UserContext";

const SideBar = () => {
  const context = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const username = Cookies.get("username");

  return (
    <Flex
      bg={"#fff"}
      borderRightWidth={"1px"}
      borderRightColor={"#eee"}
      w={"2xs"}
      h={"full"}
      pl={"6"}
      py={"6"}
      zIndex={"banner"}
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          width: "2px",
          borderRadius: "8px",
          backgroundColor: `rgba(249, 249, 249)`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: `rgba(69, 167, 53, 0.6)`,
          borderRadius: "8px",
        },
      }}
    >
      <VStack
        w={"full"}
        h={"full"}
        align={"flex-start"}
        justify={"space-between"}
      >
        <Accordion allowMultiple w="100%">
          <Stack w={"full"} spacing={2}>
            {navData.map((e, index) =>
              e.subTitle ? (
                <AccordionItem key={index} border="none">
                  <AccordionButton
                    w={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    m={0}
                    p={2}
                    color={
                      location.pathname.split("/")[1] === e.toLink.split("/")[1]
                        ? "#2EAA2E"
                        : "#000"
                    }
                    _focus={{ border: "none" }}
                    _hover={{
                      color: "#2EAA2E",
                    }}
                  >
                    <HStack
                      w={"full"}
                      align="center"
                      cursor="pointer"
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      <Icon as={e.icon} />
                      <Text w={"full"} fontSize={13} fontWeight={"normal"}>
                        {e.title}
                      </Text>
                    </HStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel p={0} my={2} mx={0}>
                    <VStack align="center" cursor="pointer" ml={6}>
                      {e.subTitle.map((e, i) => (
                        <HStack
                          transition="ease-in-out .3s"
                          key={i}
                          as={NavLink}
                          to={e.toLink}
                          alignSelf="flex-start"
                          align={"center"}
                          w={"100%"}
                          p={2}
                          borderRightWidth={"initial"}
                          borderColor={
                            location.pathname.split("/")[2] ===
                            e.toLink.split("/")[2]
                              ? "#2EAA2E"
                              : "#fff"
                          }
                          color={
                            location.pathname.split("/")[2] ===
                            e.toLink.split("/")[2]
                              ? "#2EAA2E"
                              : "#000"
                          }
                          _hover={{
                            color: "#2EAA2E",
                          }}
                        >
                          <Text w={"full"} fontSize={13} fontWeight={"normal"}>
                            {e.title}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ) : (
                <Button
                  key={index}
                  variant={"ghost"}
                  w={"full"}
                  size={"sm"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  px={"2"}
                  rounded={"none"}
                  color={
                    location.pathname.split("/")[1] === e.toLink.split("/")[1]
                      ? "#2EAA2E"
                      : "#000"
                  }
                  onClick={() => history.push(e.toLink)}
                  _focus={{
                    outline: "none",
                  }}
                  _hover={{
                    backgroundColor: "#fff",
                    color: "#2EAA2E",
                  }}
                >
                  <HStack w={"full"} align={"center"} justify={"flex-start"}>
                    <Icon w={"4"} h={"4"} as={e.icon} />
                    <Text fontSize={13} fontWeight={"normal"}>
                      {e.title}
                    </Text>
                  </HStack>
                </Button>
              )
            )}
          </Stack>
        </Accordion>
      </VStack>
    </Flex>
  );
};

export default SideBar;
