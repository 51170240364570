import React from "react";
import { Center, Icon, Text } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    <Center
      py={"1"}
      borderRadius="md"
      cursor="pointer"
      onClick={() => history.goBack()}
    >
      <Icon as={HiOutlineArrowNarrowLeft} color={"#000"} w={5} h={5} />
      <Text fontSize={13} color={"#000"} fontWeight={"normal"} ml={"2"}>
        Буцах
      </Text>
    </Center>
  );
};

export default BackButton;
