import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import UpdateCategory from "../../component/category/UpdateCategory";
import DeleteCategory from "../../component/category/DeleteCategory";

const CategoryTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("CategoryTable -->", data);

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead>
              <Tr bg={"#F6F6F6"}>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Нэр ------------ */}
                    {e.categoryName ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.categoryName}
                        </Text>
                      </Td>
                    ) : null}
                    <Td w={"20"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          <UpdateCategory
                            setTrigger={setTrigger}
                            name={e.categoryName}
                            categoryId={e._id}
                          />
                          <DeleteCategory
                            setTrigger={setTrigger}
                            categoryId={e._id}
                          />
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default CategoryTable;
