import React, { useContext } from "react";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import UserContext from "./context/UserContext";
import Main from "./pages/Main";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/auth/Login";
import Product from "./pages/product/Product";
import Member from "./pages/member/Member";
import Order from "./pages/order/Order";
import AdminList from "./pages/profile/AdminList";
import Category from "./pages/category/Category";
import AddProduct from "./pages/product/AddProduct";
import Approve from "./pages/member/approve/Approve";
import MemberType from "./pages/member/type/MemberType";
import AddMember from "./pages/member/AddMember";
import Banner from "./pages/product/banner/Banner";
import UpdateProduct from "./pages/product/UpdateProduct";

const App = () => {
  const context = useContext(UserContext);
  const accessToken = Cookies.get("accessToken");
  const isLog = Cookies.get("isLoggedIn");

  return (
    <Router>
      {isLog && accessToken ? (
        <Main>
          <Switch>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path="/product">
              <Product />
            </Route>
            <Route path="/product/add">
              <AddProduct />
            </Route>
            <Route path="/product/update">
              <UpdateProduct />
            </Route>
            <Route path="/product/banner">
              <Banner />
            </Route>
            <Route exact path="/category">
              <Category />
            </Route>
            <Route exact path="/order">
              <Order />
            </Route>
            <Route exact path="/member">
              <Member />
            </Route>
            <Route path="/member/add">
              <AddMember />
            </Route>
            <Route path="/member/type">
              <MemberType />
            </Route>
            <Route path="/member/request">
              <Approve />
            </Route>
            <Route exact path="/admin">
              <AdminList />
            </Route>
            <Redirect to={"/dashboard"} />
          </Switch>
        </Main>
      ) : (
        <>
          <Route exact path="/login">
            <Login />
          </Route>
          <Redirect to="/login" />
        </>
      )}
    </Router>
  );
};

export default App;
