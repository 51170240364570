import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PageHeader from "../../../component/header/PageHeader";
import axios from "../../../axios";
import Cookies from "js-cookie";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const Banner = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [mainBanner, setMainBanner] = useState([]);
  const [miniBanner, setMiniBanner] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/image/banner", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("banners --------->", result.data);
          setMainBanner(result.data.mainBanners);
          setMiniBanner(result.data.miniBanner);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const submitHandler = ({ data }) => {
    const formData = new FormData();
    formData.append("image", data);
    // console.log(Object.fromEntries(formData));
    axios
      .post("/api/image/banner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай нэмэгдлээ!",
          });
        }
        setTrigger(true);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error
              ? err.response.data.error
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const miniHandler = ({ data }) => {
    const formData = new FormData();
    formData.append("image", data);
    // console.log(Object.fromEntries(formData));
    axios
      .post("/api/image/miniBanner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай нэмэгдлээ!",
          });
        }
        setTrigger(true);
      })
      .catch((err) => {
        // console.log(err);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error
              ? err.response.data.error
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  const deleteHandler = () => {
    if (deleteId) {
      setBtnSpinner(true);
      axios
        .delete("/api/image/banner", {
          data: {
            id: deleteId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          onClose();
          setTrigger(true);
          setBtnSpinner(false);
          setDeleteId("");
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай устлаа!",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setBtnSpinner(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <PageHeader title={"Зар сурталчилгаа"} />;
      <VStack w={"full"} spacing={6}>
        <VStack w={"full"} bg={"#fff"} p={"3"}>
          <Box w={"full"} bg={"#eee"} py={"1"} pl={"6"}>
            <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
              Үндсэн
            </Text>
          </Box>
          <SimpleGrid columns={4} rowGap={3} columnGap={8} w={"full"}>
            <Box
              transition={"ease-in-out .3s"}
              w={"full"}
              h={"44"}
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="none"
              shadow="sm"
              pos={"relative"}
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
              _hover={{
                shadow: "md",
              }}
            >
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                // onChange={(event) => {
                //   console.log(event.target.files[0]);
                //   setSelectedImage1(event.target.files[0]);
                // }}
                onChange={(event) =>
                  submitHandler({
                    data: event.target.files[0],
                  })
                }
                cursor={"pointer"}
              />
              <Icon
                as={AiOutlineCloudUpload}
                w={"6"}
                h={"6"}
                color={"#00C5FF"}
                pos={"absolute"}
                top={"44%"}
                left={"47%"}
              />
            </Box>
            {isLoading ? (
              <Center bg={"#eee"} w={"full"} h={"44"}>
                <Spinner />
              </Center>
            ) : mainBanner.length > 0 ? (
              mainBanner.map((e, i) => (
                <Center
                  key={e._id}
                  bg={"#eee"}
                  w={"full"}
                  h={"44"}
                  pos={"relative"}
                >
                  <Tooltip label="Устгах" placement="left">
                    <Center
                      pos={"absolute"}
                      top={2}
                      right={2}
                      bg={"#979797"}
                      w={"6"}
                      h={"6"}
                      rounded={"md"}
                      cursor={"pointer"}
                      onClick={() => {
                        onOpen();
                        setDeleteId(e._id);
                      }}
                    >
                      <Icon as={IoMdClose} color={"#ff0000"} />
                    </Center>
                  </Tooltip>
                  <Image
                    src={e.imgUrl}
                    w={"full"}
                    h={"full"}
                    objectFit={"contain"}
                  />
                </Center>
              ))
            ) : null}
          </SimpleGrid>
        </VStack>
        <VStack w={"full"} bg={"#fff"} p={"3"}>
          <Box w={"full"} bg={"#eee"} py={"1"} pl={"6"}>
            <Text fontSize={15} fontWeight={"medium"} color={"#000"}>
              Жижиг
            </Text>
          </Box>
          <SimpleGrid columns={3} rowGap={3} columnGap={8} w={"full"}>
            <Box
              transition={"ease-in-out .3s"}
              w={"full"}
              h={"44"}
              borderColor="gray.300"
              borderStyle="dashed"
              borderWidth="2px"
              rounded="none"
              shadow="sm"
              pos={"relative"}
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
              _hover={{
                shadow: "md",
              }}
            >
              <Input
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                aria-hidden="true"
                accept="image/*"
                onChange={(event) =>
                  miniHandler({
                    data: event.target.files[0],
                  })
                }
                cursor={"pointer"}
              />
              <Icon
                as={AiOutlineCloudUpload}
                w={"6"}
                h={"6"}
                color={"#00C5FF"}
                pos={"absolute"}
                top={"44%"}
                left={"47%"}
              />
            </Box>
            {isLoading ? (
              <Center bg={"#eee"} w={"full"} h={"44"}>
                <Spinner />
              </Center>
            ) : miniBanner.length > 0 ? (
              miniBanner.map((e, i) => (
                <Center
                  key={e._id}
                  bg={"#eee"}
                  w={"full"}
                  h={"44"}
                  pos={"relative"}
                >
                  <Tooltip label="Устгах" placement="left">
                    <Center
                      pos={"absolute"}
                      top={2}
                      right={2}
                      bg={"#979797"}
                      w={"6"}
                      h={"6"}
                      rounded={"md"}
                      cursor={"pointer"}
                      onClick={() => {
                        onOpen();
                        setDeleteId(e._id);
                      }}
                    >
                      <Icon as={IoMdClose} color={"#ff0000"} />
                    </Center>
                  </Tooltip>
                  <Image
                    src={e.imgUrl}
                    w={"full"}
                    h={"full"}
                    objectFit={"contain"}
                  />
                </Center>
              ))
            ) : null}
          </SimpleGrid>
        </VStack>
      </VStack>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="scale"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"}>
          <ModalHeader fontSize={17}>Устгах уу?</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => {
              onClose();
              setDeleteId("");
            }}
          />
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={btnSpinner}
            >
              {btnSpinner ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Banner;
