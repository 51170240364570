import React, { useState } from "react";
import {
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import axios from "../../axios";
import Cookies from "js-cookie";
import CustomInput from "../input/CustomInput";

const AddCategory = ({ setTrigger }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submitHandler = () => {
    if (categoryName) {
      setIsDisable(true);
      axios
        .post(
          "/api/category",
          {
            categoryName: categoryName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            setCategoryName("");
            onClose();
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <>
      <HStack
        bg={"#fff"}
        px={"8"}
        py={"2"}
        border={"1px"}
        borderColor={"#ddd"}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Icon as={BiPlus} />
        <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
          Ангилал нэмэх
        </Text>
      </HStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} bg={"#fff"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Ангилал нэмэх</ModalHeader>
          <ModalCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <ModalBody w={"full"} px={"2"}>
            <VStack w={"full"} px={"4"} spacing={6}>
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Нэр"}
                value={categoryName}
                setData={setCategoryName}
                onKeyPress={(e) => {
                  if (categoryName) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              w={"full"}
              rounded={"none"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              size={"sm"}
              w={"full"}
              rounded={"none"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={submitHandler}
              disabled={categoryName && !isDisable ? false : true}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCategory;
