import React, { useRef } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import DetailTable from "./DetailTable";

const OrderDetail = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const headerData = ["#", "Нэр", "Үнэ", "Тоо ширхэг", "Нийт үнэ"];

  return (
    <>
      <Button
        ref={btnRef}
        variant={"link"}
        color={"#0079E4"}
        fontWeight={"medium"}
        fontSize={13}
        as="u"
        cursor={"pointer"}
        onClick={onOpen}
      >
        Бүтээгдэхүүн харах
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"xl"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            _focus={{
              outline: "none",
            }}
          />
          <DrawerHeader fontSize={17}>Нэхэмжлэлийн дэлгэрэнгүй</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              <VStack w={"full"}>
                <HStack w={"full"} spacing={8}>
                  <HStack w={"full"} align={"center"} justify={"space-between"}>
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      Нэхэмжлэлийн дугаар:
                    </Text>
                    <Text fontSize={13} fontWeight={"medium"} color={"#000"}>
                      {data.invoiceNo}
                    </Text>
                  </HStack>
                  <HStack w={"full"} align={"center"} justify={"space-between"}>
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      Төлөв:
                    </Text>
                    <Text
                      fontSize={13}
                      fontWeight={"medium"}
                      color={
                        data.status === "pending"
                          ? "#000"
                          : data.status === "paid"
                          ? "green"
                          : "#ff0000"
                      }
                    >
                      {data.status === "pending"
                        ? "Хүлээгдэж буй"
                        : data.status === "paid"
                        ? "Төлөгдсөн"
                        : "Цуцлагдсан"}
                    </Text>
                  </HStack>
                </HStack>
                <HStack w={"full"} spacing={8}>
                  <HStack w={"full"} align={"center"} justify={"space-between"}>
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      Огноо:
                    </Text>
                    <Text fontSize={13} fontWeight={"medium"} color={"#000"}>
                      {data.createdAt}
                    </Text>
                  </HStack>
                  <HStack w={"full"} align={"center"} justify={"space-between"}>
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      Нийт үнэ:
                    </Text>
                    <Text fontSize={13} fontWeight={"medium"} color={"#000"}>
                      {data.totalPrice.toLocaleString() + "₮"}
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
              {data.products.length > 0 ? (
                <DetailTable data={data.products} headerData={headerData} />
              ) : null}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OrderDetail;
