import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from "../../axios";
import Cookies from "js-cookie";

const ImageUpload = ({
  setImage1Id,
  setImage2Id,
  setImage3Id,
  selectedImage1,
  setSelectedImage1,
  selectedImage2,
  setSelectedImage2,
  selectedImage3,
  setSelectedImage3,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  const submitHandler = ({ type, data }) => {
    // console.log(type, data);
    setIsDisable(true);
    const formData = new FormData();
    formData.append("image", data);
    // console.log(Object.fromEntries(formData));
    axios
      .post("/api/image/product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data);
        if (result.data.success) {
          if (type === "image1") {
            setImage1Id(result.data.url);
            setSelectedImage1(result.data.url);
          }
          if (type === "image2") {
            setImage2Id(result.data.url);
            setSelectedImage2(result.data.url);
          }
          if (type === "image3") {
            setImage3Id(result.data.url);
            setSelectedImage3(result.data.url);
          }
        }
        setIsDisable(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsDisable(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error
              ? err.response.data.error
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <SimpleGrid columns={3} spacing={6} w={"full"}>
      <VStack w={"full"}>
        <Box
          w={"full"}
          h={"36"}
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="none"
          shadow="sm"
        >
          {selectedImage1 && (
            <Image
              // src={URL.createObjectURL(selectedImage1)}
              src={selectedImage1}
              height="100%"
              width="100%"
              objectFit={"contain"}
            />
          )}
        </Box>
        <HStack w={"full"}>
          <Center
            transition={"ease-in-out .2s"}
            w={"full"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            _hover={{
              shadow: "md",
            }}
            position={"relative"}
          >
            <Input
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="image/*"
              // onChange={(event) => {
              //   console.log(event.target.files[0]);
              //   setSelectedImage1(event.target.files[0]);
              // }}
              onChange={(event) =>
                submitHandler({ type: "image1", data: event.target.files[0] })
              }
            />
            <Icon as={AiOutlineCloudUpload} w={"6"} h={"6"} color={"#00C5FF"} />
          </Center>
          <Button
            transition={"ease-in-out .2s"}
            w={"36"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            onClick={() => setSelectedImage1("")}
            disabled={selectedImage1 ? false : true}
            _focus={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Icon as={IoMdClose} w={"6"} h={"6"} color={"#ff0000"} />
          </Button>
        </HStack>
      </VStack>
      <VStack>
        <Box
          w={"full"}
          h={"36"}
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="none"
          shadow="sm"
        >
          {selectedImage2 && (
            <Image
              src={selectedImage2}
              height="100%"
              width="100%"
              objectFit={"contain"}
            />
          )}
        </Box>
        <HStack w={"full"}>
          <Center
            transition={"ease-in-out .2s"}
            w={"full"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            _hover={{
              shadow: "md",
            }}
            position={"relative"}
          >
            <Input
              bg={"Red"}
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="image/*"
              onChange={(event) =>
                submitHandler({ type: "image2", data: event.target.files[0] })
              }
            />
            <Icon as={AiOutlineCloudUpload} w={"6"} h={"6"} color={"#00C5FF"} />
          </Center>
          <Button
            transition={"ease-in-out .2s"}
            w={"36"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            onClick={() => setSelectedImage2("")}
            disabled={selectedImage2 ? false : true}
            _focus={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Icon as={IoMdClose} w={"6"} h={"6"} color={"#ff0000"} />
          </Button>
        </HStack>
      </VStack>
      <VStack>
        <Box
          w={"full"}
          h={"36"}
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="none"
          shadow="sm"
        >
          {selectedImage3 && (
            <Image
              src={selectedImage3}
              height="100%"
              width="100%"
              objectFit={"contain"}
            />
          )}
        </Box>
        <HStack w={"full"}>
          <Center
            transition={"ease-in-out .2s"}
            w={"full"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            _hover={{
              shadow: "md",
            }}
            position={"relative"}
          >
            <Input
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="image/*"
              // onChange={(event) => {
              //   console.log(event.target.files[0]);
              //   setSelectedImage3(event.target.files[0]);
              // }}
              onChange={(event) =>
                submitHandler({ type: "image3", data: event.target.files[0] })
              }
            />
            <Icon as={AiOutlineCloudUpload} w={"6"} h={"6"} color={"#00C5FF"} />
          </Center>
          <Button
            transition={"ease-in-out .2s"}
            w={"36"}
            h={"8"}
            bg={"#fff"}
            border={"1px"}
            borderColor={"#ddd"}
            rounded="none"
            py={"2"}
            onClick={() => setSelectedImage3("")}
            disabled={selectedImage3 ? false : true}
            _focus={{
              outline: "none",
            }}
            _hover={{
              shadow: "md",
            }}
          >
            <Icon as={IoMdClose} w={"6"} h={"6"} color={"#ff0000"} />
          </Button>
        </HStack>
      </VStack>
    </SimpleGrid>
  );
};

export default ImageUpload;
