import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Cookies from "js-cookie";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
  Divider,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import PageHeader from "../../component/header/PageHeader";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMinusCircle,
} from "react-icons/ai";

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [deleteData, setDeleteData] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const context = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/admin", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("list ---", result.data.data);
          setAdminList(result.data.data);
          setIsLoading(false);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.message
                ? err.response.data.message
                : "Алдаа гарлаа!",
            });
          }
          setTrigger(false);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (username && password) {
      setIsDisable(true);
      axios
        .post(
          "/api/admin",
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              position: "top",
              status: "success",
              duration: 2000,
              description: "Амжилттай нэмэгдлээ!",
            });
          }
          setUsername("");
          setPassword("");
          setIsDisable(false);
          setTrigger(true);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              position: "top",
              status: "error",
              duration: 2000,
              description: "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const deleteHandler = () => {
    setBtnSpinner(true);
    axios
      .delete("/api/admin", {
        data: {
          id: deleteData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        onClose();
        setTrigger(true);
        setBtnSpinner(false);
        setDeleteData("");
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "success",
            description: "Амжилттай устлаа!",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        setBtnSpinner(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack w={"full"} spacing={4}>
      <PageHeader title={"Админы жагсаалт"} />
      <HStack
        w={"full"}
        spacing={4}
        align={"flex-start"}
        justify={"flex-start"}
      >
        <VStack>
          <Center w={"full"} h={"10"} bg={"#2EAA2E"}>
            <Text fontSize={14} fontWeight={"medium"} color={"#fff"}>
              Хэрэглэгч үүсгэх
            </Text>
          </Center>
          <VStack bg={"#fff"} rounded={"none"} w={"xs"} py={6} px={6}>
            <Stack spacing={2} w={"full"}>
              <Input
                type="text"
                placeholder="Нэр"
                _placeholder={{
                  fontSize: 13,
                }}
                fontWeight={"normal"}
                fontSize={"15"}
                _hover={{
                  borderColor: "#2EAA2E",
                }}
                _focus={{
                  outline: "none",
                  borderColor: "#2EAA2E",
                }}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="Нууц үг"
                  _placeholder={{
                    fontSize: 13,
                  }}
                  fontWeight={"normal"}
                  fontSize={"15"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  _hover={{
                    borderColor: "#2EAA2E",
                  }}
                  _focus={{
                    outline: "none",
                    borderColor: "#2EAA2E",
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (username && password) {
                        submitHandler();
                      }
                    }
                  }}
                />
                <InputRightElement>
                  <Icon
                    as={show ? AiOutlineEyeInvisible : AiOutlineEye}
                    w={5}
                    h={5}
                    mr={"2"}
                    color="#000"
                    onClick={() => setShow(!show)}
                    cursor={"pointer"}
                  />
                </InputRightElement>
              </InputGroup>
              <Stack spacing={10}>
                <Button
                  bg={"#2EAA2E"}
                  color={"#fff"}
                  fontSize={13}
                  _hover={{
                    bg: "#5AB54B",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  fontWeight={"normal"}
                  disabled={username && password && !isDisable ? false : true}
                  onClick={() => submitHandler()}
                >
                  {isDisable ? <Spinner mr={2} size="sm" /> : null}
                  Хадгалах
                </Button>
              </Stack>
            </Stack>
          </VStack>
        </VStack>
        <VStack w={"full"}>
          <HStack w={"full"} h={"10"} bg={"#2EAA2E"} px={"10"}>
            <Stack w={"20"}>
              <Text fontSize={14} color={"#fff"} fontWeight={"medium"}>
                #
              </Text>
            </Stack>
            <Stack w={"full"}>
              <Text fontSize={14} color={"#fff"} fontWeight={"medium"}>
                Хэрэглэгчийн нэр
              </Text>
            </Stack>
            <Stack w={"full"}>
              <Text fontSize={14} color={"#fff"} fontWeight={"medium"}>
                Хандах эрх
              </Text>
            </Stack>
            <Stack w={"32"}>
              <Text fontSize={14} color={"#fff"} fontWeight={"medium"}>
                Үйлдэл
              </Text>
            </Stack>
          </HStack>
          <VStack w={"full"} bg={"#fff"}>
            {isLoading ? (
              <Spinner my={"2"} color={"#2EAA2E"} />
            ) : adminList.length > 0 ? (
              adminList.map((e, i) => (
                <HStack key={i} w={"full"} bg={"#fff"} px={"10"} py={"2"}>
                  <Stack w={"20"}>
                    <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                      {i + 1}
                    </Text>
                  </Stack>
                  <Stack w={"full"}>
                    <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                      {e.username}
                    </Text>
                  </Stack>
                  <Stack w={"full"}>
                    <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                      {e.role}
                    </Text>
                  </Stack>
                  <Stack w={"32"} align={"center"}>
                    <Icon
                      as={AiOutlineMinusCircle}
                      w={"5"}
                      h={"5"}
                      color={"#ff0000"}
                      cursor={"pointer"}
                      onClick={() => {
                        setDeleteData(e._id);
                        onOpen();
                      }}
                    />
                  </Stack>
                </HStack>
              ))
            ) : null}
          </VStack>
        </VStack>
      </HStack>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={"xs"}>
          <ModalHeader fontSize={17}>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              px={"8"}
              fontSize={14}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              px={"8"}
              fontSize={14}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={btnSpinner}
            >
              {btnSpinner ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default AdminList;
