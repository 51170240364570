import React, { useEffect, useState } from "react";
import { useToast, VStack } from "@chakra-ui/react";
import PageHeader from "../../../component/header/PageHeader";
import RequestTable from "./RequestTable";
import axios from "../../../axios";
import Cookies from "js-cookie";
import OrganizationTable from "./OrganizationTable";

const Approve = () => {
  const [data, setData] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");

  useEffect(() => {
    if (trigger) {
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/client_request/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("client_request --------->", result.data);
          setData(result.data.individual);
          setOrganization(result.data.organization);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Овог, Нэр",
    "Утас",
    "И-мэйл",
    "Хаяг",
    "Хугацаа",
    "Үйлдэл",
  ];

  const headerOrganizationData = [
    "#",
    "Байгууллагын нэр",
    "Байгууллагын регистр",
    "Утас",
    "И-мэйл",
    "Төрөл",
    "Хаяг",
    "Хугацаа",
    "Үйлдэл",
  ];

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <PageHeader title={"Хэрэглэгчийн бүртгүүлэх хүсэлт"} />
      <VStack w={"full"} pb={"6"} spacing={4}>
        <RequestTable
          data={data}
          headerData={headerData}
          isLoading={isLoading}
          setTrigger={setTrigger}
        />
        {/* <Pagination
          // showQuickJumper
          pageSize={30}
          current={currentPage}
          onChange={changePage}
          total={totalCount}
          locale={localeInfo}
          style={{
            alignSelf: "flex-end",
          }}
        /> */}
      </VStack>
      <VStack w={"full"} pb={"6"} spacing={4}>
        <OrganizationTable
          organization={organization}
          headerData={headerOrganizationData}
          isLoading={isLoading}
          setTrigger={setTrigger}
        />
        {/* <Pagination
          // showQuickJumper
          pageSize={30}
          current={currentPage}
          onChange={changePage}
          total={totalCount}
          locale={localeInfo}
          style={{
            alignSelf: "flex-end",
          }}
        /> */}
      </VStack>
    </VStack>
  );
};

export default Approve;
