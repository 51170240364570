import React from "react";
import { Text, VStack } from "@chakra-ui/react";

const Dashboard = () => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
      <Text fontSize={15} fontWeight={"normal"} color={"#000"}>
        Хяналтын самбар
      </Text>
    </VStack>
  );
};

export default Dashboard;
