import React, { useEffect, useState } from "react";
import { HStack, Icon, Input, Text, useToast, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../component/header/PageHeader";
import CreateType from "../../../component/member.js/type/CreateType";
import TypeTable from "./TypeTable";
import axios from "../../../axios";
import Cookies from "js-cookie";

const MemberType = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/client/client_type", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Product --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = ["#", "Нэр", "Дэлгэрэнгүй", "Үйлдэл"];

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <PageHeader title={"Хэрэглэгчийн төрөл"} />
      <CreateType setTrigger={setTrigger} />
      <TypeTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
    </VStack>
  );
};

export default MemberType;
