import React, { useState, createContext } from "react";
import { Box, useToast } from "@chakra-ui/react";
import axios from "../axios";
import jwt from "jwt-decode";
import Cookies from "js-cookie";
const UserContext = createContext();

export const UserStore = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [username, setUsername] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const toast = useToast();
  const id = "test-toast";

  const loginSuccess = (accToken, refToken, name) => {
    Cookies.set("accessToken", accToken);
    Cookies.set("refreshToken", refToken);
    Cookies.set("username", name);
    Cookies.set("isLoggedIn", true);
    setAccessToken(accToken);
    setRefreshToken(refToken);
    setUsername(name);
    setIsLoggedIn(true);
  };

  const loginHandler = async (user, pass) => {
    setIsLoading(true);
    const loginData = {
      username: user,
      password: pass,
    };
    axios
      .post("/auth/login", loginData)
      .then((result) => {
        // console.log(result.data);
        const decode = jwt(result.data.accessToken);
        // console.log(decode);
        loginSuccess(
          result.data.accessToken,
          result.data.refreshToken,
          decode.username
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description:
              error.response.status === 400
                ? "Таны нэвтрэх нэр эсвэл нууц үг буруу байна!"
                : "Холболтын алдаа",
          });
        }
      });
  };

  const logoutHandler = async () => {
    // console.log("logout");
    removeCookies();
    setIsLoggedIn(false);
    setUsername("");
    setAccessToken("");
    setRefreshToken("");
    // const refToken = Cookies.get("refreshToken");
    // setIsLoading(true);
    // try {
    //   axios
    //     .post("/auth/logout", { TheRefreshToken: refToken })
    //     .then((result) => {
    //       removeCookies();
    //       setIsLoggedIn(false);
    //       setUsername("");
    //       setAccessToken("");
    //       setRefreshToken("");
    //       setIsLoading(false);
    //     })
    //     .catch((error) => {
    //       setIsLoading(false);
    //     });
    // } catch (e) {
    //   // console.log(e);
    // }
  };

  const removeCookies = () => {
    Cookies.remove("username");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        accessToken,
        refreshToken,
        username,
        loginHandler,
        removeCookies,
        isLoading,
        setIsLoading,
        logoutHandler,
        trigger,
        setTrigger,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
