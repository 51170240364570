import { RiHome3Line } from "react-icons/ri";
import { AiOutlineOrderedList, AiOutlineUser } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";

export const navData = [
  {
    title: "Хяналтын самбар",
    toLink: "/dashboard",
    icon: RiHome3Line,
  },
  {
    title: "Бүтээгдэхүүн",
    toLink: "/product",
    icon: TbListDetails,
  },
  {
    title: "Ангилал",
    toLink: "/category",
    icon: BiCategoryAlt,
  },
  {
    title: "Захиалга",
    toLink: "/order",
    icon: MdOutlineFavoriteBorder,
  },
  {
    title: "Хэрэглэгч",
    toLink: "/member",
    icon: AiOutlineUser,
    // subTitle: [
    //   {
    //     title: "",
    //     toLink: "",
    //   },
    //   {
    //     title: "",
    //     toLink: "",
    //   },
    // ],
  },
];
