import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  IconButton,
  Input,
  Select,
  useToast,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../../axios";
import Cookies from "js-cookie";

const RequestTable = ({ data, headerData, isLoading, setTrigger }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [userId, setUserId] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("RequestTable -->", data);

  const submitHandler = (value) => {
    if (value) {
      setIsDisable(true);
      axios
        .put(
          "/api/client_request/",
          {
            id: value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const deleteHandler = () => {
    if (userId) {
      setIsDisable(true);
      axios
        .delete("/api/client_request/", {
          data: {
            id: userId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          onClose();
          setUserId("");
          setTrigger(true);
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead>
              <Tr bg={"#F6F6F6"}>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Овог Нэр ------------ */}
                    {e.firstName && e.lastName ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.firstName + " " + e.lastName}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Утас ------------ */}
                    {e.phoneNo ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.phoneNo}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ И-мэйл ------------ */}
                    {e.email ? (
                      <Td
                        maxW={"full"}
                        flexWrap={"wrap"}
                        whiteSpace={"normal"}
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                          noOfLines={2}
                        >
                          {e.email}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Хаяг ------------ */}
                    {e.address ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                        maxW={"64"}
                        whiteSpace={"pre-wrap"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.address}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Хугацаа ------------ */}
                    {e.atTime ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.atTime.slice(0, 10) +
                            " / " +
                            e.atTime.slice(11, 16)}
                        </Text>
                      </Td>
                    ) : null}

                    <Td w={"20"}>
                      <HStack>
                        <IconButton
                          isLoading={isDisable}
                          variant="solid"
                          size={"sm"}
                          colorScheme="green"
                          aria-label="Done"
                          fontSize="15"
                          icon={<Icon as={IoMdCheckmark} />}
                          onClick={() => submitHandler(e._id)}
                          _focus={{
                            outline: "none",
                          }}
                        />
                        <IconButton
                          variant="solid"
                          size={"sm"}
                          colorScheme="red"
                          aria-label="Close"
                          fontSize="15"
                          icon={<Icon as={IoMdClose} />}
                          onClick={() => {
                            onOpen();
                            setUserId(e._id);
                          }}
                          _focus={{
                            outline: "none",
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="scale"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"}>
          <ModalHeader fontSize={17}>Устгах уу?</ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => {
              onClose();
              setUserId("");
            }}
          />
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setUserId("");
              }}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default RequestTable;
