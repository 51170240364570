import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Select,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PageHeader from "../../component/header/PageHeader";
import CustomInput from "../../component/input/CustomInput";
import Cookies from "js-cookie";
import axios from "../../axios";
import PassInput from "../../component/input/PassInput";
import { useHistory } from "react-router-dom";

const AddMember = () => {
  const [clientType, setClientType] = useState([]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [male, setMale] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  // ---------------- organization ------------------
  const [organizationType, setOrganizationType] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [orgaPhoneNumber, setOrgaPhoneNumber] = useState("");
  const [orgaAddress, setOrgaAddress] = useState("");
  const [orgaEmail, setOrgaEmail] = useState("");
  const [orgaPassword, setOrgaPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  // const context = useContext(UserContext);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearOrganizationData = () => {
    setOrganizationName("");
    setOrganizationType("");
    setOrganizationId("");
    setOrgaPhoneNumber("");
    setOrgaEmail("");
    setOrgaAddress("");
    setOrgaPassword("");
  };

  const clearData = () => {
    setLastName("");
    setFirstName("");
    setMale(null);
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setAddress("");
  };

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/api/client/client_type", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        setIsLoading(false);
        // console.log("type --------->", result.data);
        setClientType(result.data.data);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error
              ? err.response.data.error
              : "Алдаа гарлаа!",
          });
        }
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  const submitHandler = () => {
    if (
      lastName &&
      firstName &&
      (male === true || male === false) &&
      email &&
      phoneNumber &&
      password &&
      address
    ) {
      setIsDisable(true);
      axios
        .post(
          "/api/client/register_individual",
          {
            firstName: firstName,
            lastName: lastName,
            password: password,
            phoneNo: phoneNumber,
            email: email,
            isMale: male,
            address: address,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            history.push("/member");
            clearData();
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const organizationHandler = () => {
    if (
      orgaPhoneNumber &&
      organizationName &&
      organizationId &&
      organizationType &&
      orgaEmail &&
      orgaAddress &&
      orgaPassword
    ) {
      setIsDisable(true);
      axios
        .post(
          "/api/client/register_organization",
          {
            organizationName: organizationName,
            password: orgaPassword,
            phoneNo: orgaPhoneNumber,
            email: orgaEmail,
            organizationType: organizationType,
            address: orgaAddress,
            organizationId: organizationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            history.push("/member");
            clearOrganizationData();
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <PageHeader title={"Хэрэглэгч бүртгэх"} />
      <HStack
        w={"full"}
        spacing={8}
        align={"flex-start"}
        justify={"flex-start"}
      >
        <VStack
          w={"full"}
          bg={"#fff"}
          align={"flex-start"}
          justify={"flex-start"}
          py={"5"}
          px={"6"}
          spacing={4}
        >
          <VStack
            w={"full"}
            spacing={0}
            align={"flex-start"}
            justify={"flex-start"}
          >
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              Байгууллага
            </Text>
            <Divider />
          </VStack>

          <SimpleGrid columns={2} rowGap={3} columnGap={8} w={"full"}>
            <CustomInput
              title={"Байгууллагын нэр"}
              value={organizationName}
              setData={setOrganizationName}
            />
            <CustomInput
              title={"Байгууллагын регистр"}
              value={organizationId}
              setData={setOrganizationId}
            />
            <CustomInput
              title={"Утас"}
              value={orgaPhoneNumber}
              setData={setOrgaPhoneNumber}
              type={"number"}
            />
            <PassInput
              title={"Нууц үг"}
              value={orgaPassword}
              setData={setOrgaPassword}
            />
            <CustomInput
              title={"И-мэйл"}
              value={orgaEmail}
              setData={setOrgaEmail}
            />
            <VStack
              w={"full"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Төрөл
              </Text>
              <Select
                w={"full"}
                // size={"sm"}
                rounded={"none"}
                fontWeight={"normal"}
                fontSize={13}
                color={"#000"}
                value={organizationType}
                onChange={(e) => setOrganizationType(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#2EAA2E",
                }}
                _hover={{
                  outline: "none",
                }}
              >
                <option selected hidden disabled value="">
                  -
                </option>
                {clientType
                  ? clientType.map((e) => (
                      <option key={e._id} value={e._id}>{e.name}</option>
                    ))
                  : null}
              </Select>
            </VStack>
            <CustomInput
              title={"Хаяг"}
              value={orgaAddress}
              setData={setOrgaAddress}
              onKeyPress={(e) => {
                if (
                  orgaPhoneNumber &&
                  organizationName &&
                  organizationId &&
                  organizationType &&
                  orgaEmail &&
                  orgaAddress &&
                  orgaPassword
                ) {
                  if (e.key === "Enter") {
                    organizationHandler();
                  }
                }
              }}
            />
          </SimpleGrid>
          <HStack
            w={"full"}
            align={"center"}
            justify={"space-between"}
            spacing={8}
          >
            <Button
              w={"full"}
              bg={"#ddd"}
              rounded={"none"}
              color={"#000"}
              fontSize={13}
              fontWeight={"medium"}
              alignSelf={"end"}
              onClick={() => clearOrganizationData()}
              _focus={{
                outline: "none",
              }}
            >
              Цэвэрлэх
            </Button>
            <Button
              w={"full"}
              bg={"#2EAA2E"}
              rounded={"none"}
              color={"#fff"}
              fontSize={13}
              fontWeight={"medium"}
              alignSelf={"end"}
              onClick={organizationHandler}
              disabled={
                !isDisable &&
                orgaPhoneNumber &&
                organizationName &&
                organizationId &&
                organizationType &&
                orgaEmail &&
                orgaAddress &&
                orgaPassword
                  ? false
                  : true
              }
              _focus={{
                outline: "none",
              }}
              _hover={{
                bg: "#40BF40",
              }}
            >
              Хадгалах
            </Button>
          </HStack>
        </VStack>
        <VStack
          w={"full"}
          bg={"#fff"}
          align={"flex-start"}
          justify={"flex-start"}
          py={"5"}
          px={"6"}
          spacing={4}
        >
          <VStack
            w={"full"}
            spacing={0}
            align={"flex-start"}
            justify={"flex-start"}
          >
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              Хувь хүн
            </Text>
            <Divider />
          </VStack>

          <SimpleGrid columns={2} rowGap={3} columnGap={8} w={"full"}>
            <CustomInput
              title={"Овог"}
              value={lastName}
              setData={setLastName}
            />
            <CustomInput
              title={"Нэр"}
              value={firstName}
              setData={setFirstName}
            />
            <CustomInput
              title={"Утас"}
              value={phoneNumber}
              setData={setPhoneNumber}
              type={"number"}
            />
            <PassInput
              title={"Нууц үг"}
              value={password}
              setData={setPassword}
            />
            <CustomInput title={"И-мэйл"} value={email} setData={setEmail} />
            <VStack
              w={"full"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Хүйс:
              </Text>

              <HStack w={"72"} spacing={6}>
                <Checkbox
                  colorScheme="green"
                  size={"md"}
                  value={male}
                  onChange={() => setMale(true)}
                  isChecked={male === true ? true : false}
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                >
                  <Text fontSize={14} color="#000" fontWeight={"normal"}>
                    Эрэгтэй
                  </Text>
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  size={"md"}
                  value={male}
                  onChange={() => setMale(false)}
                  isChecked={male === false ? true : false}
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                >
                  <Text fontSize={14} color="#000" fontWeight={"normal"}>
                    Эмэгтэй
                  </Text>
                </Checkbox>
              </HStack>
            </VStack>
            <CustomInput
              title={"Хаяг"}
              value={address}
              setData={setAddress}
              onKeyPress={(e) => {
                if (
                  phoneNumber &&
                  firstName &&
                  lastName &&
                  address &&
                  (male === true || male === false) &&
                  email &&
                  password
                ) {
                  if (e.key === "Enter") {
                    submitHandler();
                  }
                }
              }}
            />
          </SimpleGrid>
          <HStack
            w={"full"}
            align={"center"}
            justify={"space-between"}
            spacing={8}
          >
            <Button
              w={"full"}
              bg={"#ddd"}
              rounded={"none"}
              color={"#000"}
              fontSize={13}
              fontWeight={"medium"}
              alignSelf={"end"}
              onClick={() => clearData()}
              _focus={{
                outline: "none",
              }}
            >
              Цэвэрлэх
            </Button>
            <Button
              w={"full"}
              bg={"#2EAA2E"}
              rounded={"none"}
              color={"#fff"}
              fontSize={13}
              fontWeight={"medium"}
              alignSelf={"end"}
              onClick={submitHandler}
              disabled={
                !isDisable &&
                phoneNumber &&
                firstName &&
                lastName &&
                address &&
                (male === true || male === false) &&
                email &&
                password
                  ? false
                  : true
              }
              _focus={{
                outline: "none",
              }}
              _hover={{
                bg: "#40BF40",
              }}
            >
              Хадгалах
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default AddMember;
