import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PageHeader from "../../component/header/PageHeader";
import Cookies from "js-cookie";
import ImageUpload from "./ImageUpload";
import axios from "../../axios";
import CustomInput from "../../component/input/CustomInput";
import { useLocation, useHistory } from "react-router-dom";
import { MdRemove } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

const UpdateProduct = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [productName, setProductName] = useState(
    location?.state?.data?.productName
  );
  const [description, setDescription] = useState(
    location?.state?.data?.description
  );
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState(
    location?.state?.data?.categoryId
  );
  const [vLink, setVLink] = useState(location?.state?.data?.videoDescription);
  const [price, setPrice] = useState(location?.state?.data?.price);
  const [reserve, setReserve] = useState(location?.state?.data?.reserve);
  const [principle, setPrinciple] = useState(
    location?.state?.data?.specification?.principle
  );
  const [certificates, setCertificates] = useState(
    location?.state?.data?.specification?.Certificates
  );
  const [temperature, setTemperature] = useState(
    location?.state?.data?.specification?.Temperature
  );
  const [packaging, setPackaging] = useState(
    location?.state?.data?.specification?.Packaging
  );
  const [format, setFormat] = useState(
    location?.state?.data?.specification?.Format
  );
  const [sample, setSample] = useState(
    location?.state?.data?.specification?.Sample
  );
  const [shelfLife, setShelfLife] = useState(
    location?.state?.data?.specification?.ShelfLife
  );
  const [image1Id, setImage1Id] = useState(location?.state?.data?.images[0]);
  const [image2Id, setImage2Id] = useState(location?.state?.data?.images[1]);
  const [image3Id, setImage3Id] = useState(location?.state?.data?.images[2]);
  const [tag, setTag] = useState(location?.state?.data?.tags);
  const [pdfUrl, setPdfUrl] = useState(location?.state?.data?.descriptionPdf);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();
  const [salePercentage, setSalePercentage] = useState("");
  const [days, setDays] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedImage1, setSelectedImage1] = useState(
    location?.state?.data?.images[0]
  );
  const [selectedImage2, setSelectedImage2] = useState(
    location?.state?.data?.images[1]
  );
  const [selectedImage3, setSelectedImage3] = useState(
    location?.state?.data?.images[2]
  );
  const [featureArray, setFeatureArray] = useState(
    location?.state?.data?.feature
  );

  const incInputArray = () => {
    setFeatureArray((prevState) => {
      return [...prevState, ""];
    });
  };

  const updateInputValue = (i, e) => {
    const newInput = [...featureArray];
    newInput[i] = e;
    setFeatureArray(newInput);
  };

  const removeInput = (index) => {
    setFeatureArray(featureArray.filter((e, i) => i !== index));
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/api/category", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        setIsLoading(false);
        // console.log("Category --------->", result.data);
        setCategoryList(result.data.data);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  const submitHandler = () => {
    if (
      productName &&
      categoryId &&
      description &&
      price &&
      reserve &&
      featureArray &&
      vLink &&
      image1Id &&
      image2Id &&
      image3Id
    ) {
      setIsDisable(true);
      const data = {
        productId: location?.state?.data?._id,
        productName: productName,
        category: categoryId,
        description: description,
        feature: featureArray,
        specification: {
          principle: principle,
          Certificates: certificates,
          Temperature: temperature,
          Packaging: packaging,
          Format: format,
          Sample: sample,
          ShelfLife: shelfLife,
        },
        images: [image1Id, image2Id, image3Id],
        videoDescription: vLink,
        price: price,
        reserve: reserve,
        certificate: "not certain yet",
        tags: tag,
        descriptionPdf: pdfUrl,
      };
      axios
        .put("/api/product/whole", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            history.push("/product");
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const checkHandler = (value) => {
    if (tag.every((e) => e !== value)) {
      const clone = [...tag];
      clone.push(value);
      setTag(clone);
    } else {
      setTag(tag.filter((e, i) => e !== value));
    }
  };

  const saleHandler = () => {
    if (location?.state?.data?._id && tag && salePercentage && days) {
      setIsDisable(true);
      const data = {
        productId: location?.state?.data?._id,
        productTag: tag,
        salePercentage: salePercentage,
        days: days,
      };
      axios
        .put("/api/product/tag", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setSalePercentage("");
            setDays("");
            onClose();
            history.push("/product");
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const pdfHandler = ({ type, data }) => {
    // console.log(type, data);
    setIsDisable(true);
    const formData = new FormData();
    formData.append("image", data);
    // console.log(Object.fromEntries(formData));
    axios
      .post("/api/image/product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data);
        if (result.data.success) {
          setPdfUrl(result.data.url);
        }
        setIsDisable(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsDisable(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description: err.response.data.error
              ? err.response.data.error
              : "Алдаа гарлаа!",
          });
        }
      });
  };

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <PageHeader title={"Бүтээгдэхүүний мэдээлэл засах"} />
      <VStack
        w={"full"}
        align="flex-start"
        justify={"flex-start"}
        bg={"#fff"}
        shadow={"base"}
        p={"4"}
        rounded={"sm"}
        spacing={4}
      >
        <HStack
          w={"full"}
          align="flex-start"
          justify={"flex-start"}
          spacing={8}
        >
          <VStack w={"xl"} align="flex-start" justify={"flex-start"}>
            <CustomInput
              title={"Бүтээгдэхүүний нэр"}
              value={productName}
              setData={setProductName}
            />
            <VStack
              w={"full"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Дэлгэрэнгүй:
              </Text>
              <Textarea
                placeholder="-"
                w={"xl"}
                size={"sm"}
                rounded={"none"}
                fontWeight={"normal"}
                fontSize={13}
                color={"#000"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#2EAA2E",
                }}
                _hover={{
                  outline: "none",
                }}
              />
            </VStack>

            <VStack
              w={"full"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Ангилал:
              </Text>
              <Select
                w={"xl"}
                // size={"sm"}
                rounded={"none"}
                fontWeight={"normal"}
                fontSize={13}
                color={"#000"}
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                _focus={{
                  outline: "none",
                  borderColor: "#30A1E6",
                }}
                _hover={{
                  outline: "none",
                }}
              >
                <option selected hidden disabled value="">
                  -
                </option>
                {categoryList.length > 0
                  ? categoryList.map((e, i) => (
                      <option key={i} value={e._id}>
                        {e.categoryName}
                      </option>
                    ))
                  : null}
              </Select>
            </VStack>

            <VStack
              w={"xl"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Зураг:
              </Text>
              <ImageUpload
                setImage1Id={setImage1Id}
                setImage2Id={setImage2Id}
                setImage3Id={setImage3Id}
                selectedImage1={selectedImage1}
                setSelectedImage1={setSelectedImage1}
                selectedImage2={selectedImage2}
                setSelectedImage2={setSelectedImage2}
                selectedImage3={selectedImage3}
                setSelectedImage3={setSelectedImage3}
              />
            </VStack>

            <CustomInput
              title={"Бичлэг (URL)"}
              value={vLink}
              setData={setVLink}
            />
            <VStack
              w={"full"}
              align="flex-start"
              justify={"flex-start"}
              spacing={0}
            >
              <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                Тайлбар (PDF):
              </Text>
              <Input
                placeholder={pdfUrl}
                w={"full"}
                size={"md"}
                rounded={"none"}
                fontWeight={"normal"}
                fontSize={13}
                color={"#000"}
                type="file"
                accept="application/pdf"
                onChange={(event) =>
                  pdfHandler({ data: event.target.files[0] })
                }
                _focus={{
                  outline: "none",
                  borderColor: "#2EAA2E",
                }}
                _hover={{
                  outline: "none",
                }}
              />
            </VStack>
          </VStack>
          <HStack
            w={"full"}
            align="flex-start"
            justify={"flex-start"}
            spacing={8}
          >
            <VStack w={"full"} align="flex-start" justify={"flex-start"}>
              <CustomInput title={"Үнэ"} value={price} setData={setPrice} />
              <CustomInput
                title={"Тоо ширхэг"}
                value={reserve}
                setData={setReserve}
              />

              <VStack
                w={"full"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                p={"3"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={4}
              >
                <Center bg={"#DFFFDF"} w={"full"} px={"6"} py={"2"}>
                  <Text
                    as={"i"}
                    fontSize={13}
                    color="#000"
                    fontWeight={"normal"}
                    textAlign={"center"}
                  >
                    checkleel ymar tag-tai bolgomoor bn tiishee shuud ornoo!!!
                  </Text>
                </Center>
                <HStack w={"full"} spacing={8}>
                  <Checkbox
                    colorScheme="green"
                    size={"md"}
                    value={tag.find((e, i) => e === "special") ? true : false}
                    onChange={() => checkHandler("special")}
                    isChecked={
                      tag.find((e, i) => e === "special") ? true : false
                    }
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Онцлох
                    </Text>
                  </Checkbox>
                  <Checkbox
                    colorScheme="green"
                    size={"md"}
                    value={tag.find((e, i) => e === "hot") ? true : false}
                    onChange={() => checkHandler("hot")}
                    isChecked={tag.find((e, i) => e === "hot") ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эрэлттэй
                    </Text>
                  </Checkbox>
                  <Checkbox
                    isDisabled
                    colorScheme="green"
                    size={"md"}
                    value={tag.find((e, i) => e === "sale") ? true : false}
                    isChecked={tag.find((e, i) => e === "sale") ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Хямдралтай
                    </Text>
                  </Checkbox>
                </HStack>
              </VStack>
              <Divider />

              <HStack
                w={"full"}
                bg={"#fff"}
                px={"8"}
                py={"2"}
                border={"1px"}
                borderColor={"#ddd"}
                align={"center"}
                justify={"center"}
                onClick={() => incInputArray()}
                cursor={"pointer"}
              >
                <Icon as={BiPlus} />
                <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
                  Онцлог нэмэх
                </Text>
              </HStack>

              {featureArray.length > 0
                ? featureArray.map((e, i) => (
                    <InputGroup key={i}>
                      <Input
                        placeholder="-"
                        w={"full"}
                        rounded={"none"}
                        fontWeight={"normal"}
                        fontSize={13}
                        color={"#000"}
                        value={e}
                        onChange={(e) => updateInputValue(i, e.target.value)}
                        _focus={{
                          outline: "none",
                          borderColor: "#2EAA2E",
                        }}
                        _hover={{
                          outline: "none",
                        }}
                      />
                      <InputRightElement>
                        <Icon
                          as={MdRemove}
                          w={5}
                          h={5}
                          color="#ff0000"
                          onClick={() => removeInput(i)}
                          cursor={"pointer"}
                        />
                      </InputRightElement>
                    </InputGroup>
                  ))
                : null}
            </VStack>

            {/* ---------------- Тодорхойлолт ----------------- */}
            <VStack w={"full"} align="flex-start" justify={"flex-start"}>
              <CustomInput
                title={"Зарчим"}
                value={principle}
                setData={setPrinciple}
              />
              <CustomInput
                title={"Сертификат"}
                value={certificates}
                setData={setCertificates}
              />
              <CustomInput
                title={"Температур"}
                value={temperature}
                setData={setTemperature}
              />
              <CustomInput
                title={"Савлагаа"}
                value={packaging}
                setData={setPackaging}
              />
              <CustomInput
                title={"Формат"}
                value={format}
                setData={setFormat}
              />
              <CustomInput title={"Сорьц"} value={sample} setData={setSample} />
              <CustomInput
                title={"Хадгалах хугацаа"}
                value={shelfLife}
                setData={setShelfLife}
              />
            </VStack>
          </HStack>
        </HStack>
        <Divider />
        <HStack w={"full"} align={"center"} justify={"space-between"}>
          <Button
            transition={"ease-in-out .3s"}
            w={"xs"}
            bg={"#fff"}
            rounded="none"
            border={"1px"}
            borderColor={"#ff0000"}
            color={"#ff0000"}
            fontSize={13}
            fontWeight={"medium"}
            alignSelf={"end"}
            onClick={() => {
              onOpen();
              checkHandler("sale");
            }}
            disabled={
              location?.state?.data?.salePercentage &&
              location?.state?.data?.saleEndsAt
                ? true
                : false
            }
            _focus={{
              outline: "none",
            }}
            _hover={{
              shadow: "lg",
            }}
          >
            Хямдралтай болгох
          </Button>
          <Button
            w={"xs"}
            bg={"#2EAA2E"}
            rounded="none"
            color={"#fff"}
            fontSize={13}
            fontWeight={"medium"}
            alignSelf={"end"}
            onClick={submitHandler}
            disabled={isDisable}
            _focus={{
              outline: "none",
            }}
            _hover={{
              bg: "#40BF40",
            }}
          >
            {isDisable ? <Spinner mr={2} size="sm" /> : null}
            Хадгалах
          </Button>
        </HStack>
      </VStack>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} bg={"#fff"} rounded={"sm"}>
          <ModalHeader fontSize={17}>
            Хямдралтай бүтээгдэхүүн болгох
          </ModalHeader>
          <ModalBody w={"full"} px={"2"}>
            <VStack w={"full"} px={"4"} spacing={2}>
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Хямдралын хувь"}
                value={salePercentage}
                setData={setSalePercentage}
                type={true}
                onKeyPress={(e) => {}}
              />
              <CustomInput
                title={"Нийт өдрийн тоо"}
                value={days}
                setData={setDays}
                type={true}
                onKeyPress={(e) => {
                  if (salePercentage && days) {
                    if (e.key === "Enter") {
                      saleHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              w={"full"}
              rounded={"none"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                checkHandler("sale");
              }}
            >
              Болих
            </Button>
            <Button
              colorScheme="green"
              size={"sm"}
              w={"full"}
              rounded={"none"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={saleHandler}
              disabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default UpdateProduct;
