import React from "react";
import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import BackButton from "../button/BackButton";

const PageHeader = ({ title }) => {
  return (
    <VStack w={"full"} align={"center"} justify={"center"}>
      <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
        <BackButton />
        <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
          {title}
        </Text>
      </HStack>
      <Divider />
    </VStack>
  );
};

export default PageHeader;
