import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  HStack,
  IconButton,
  Input,
  Select,
  useToast,
  Image,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../axios";
import Cookies from "js-cookie";
import DeleteProduct from "../../component/product/DeleteProduct";
import { useHistory } from "react-router-dom";

const ProductTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("ProductTable -->", data);
  const [isDisable, setIsDisable] = useState(false);
  const [editId, setEditId] = useState("");
  const [bonusType, setBonusType] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [reachPoint, setReachPoint] = useState(null);
  const [rank, setRank] = useState(null);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const history = useHistory();

  const submitHandler = () => {
    if (bonusType && bonus && reachPoint && rank && editId) {
      setIsDisable(true);
      axios
        .put(
          "/api/rank/",
          {
            _id: editId,
            loyaltyType: bonusType,
            loyalty: bonus,
            reachPoint: reachPoint,
            name: rank,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            setBonusType(null);
            setBonus(null);
            setReachPoint(null);
            setEditId(null);
            setRank(null);
          }
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisable(false);
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead>
              <Tr bg={"#F6F6F6"}>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Зураг ------------ */}
                    {e.images ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        {/* <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.productName}
                        </Text> */}
                        <Image src={e.images[1]} w={"10"} objectFit={"cover"} />
                      </Td>
                    ) : null}
                    {/* ------------ Нэр ------------ */}
                    {e.productName ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.productName}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Дэлгэрэнгүй ------------ */}
                    {e.description ? (
                      <Td
                        maxW={"full"}
                        flexWrap={"wrap"}
                        whiteSpace={"normal"}
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                          noOfLines={2}
                        >
                          {e.description}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Ангилал ------------ */}
                    {e.category ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.category}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Үнэ ------------ */}
                    {e.price ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.price.toLocaleString() + "₮"}
                        </Text>
                      </Td>
                    ) : null}

                    <Td w={"20"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          <MenuItem
                            color={"#000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() => {
                              history.push("/product/update", { data: e });
                              setBonusType(e.loyaltyType);
                              setBonus(e.loyalty);
                              setReachPoint(e.reachPoint);
                              setEditId(e._id);
                              setRank(e.name);
                            }}
                          >
                            Засварлах
                          </MenuItem>
                          <DeleteProduct
                            setTrigger={setTrigger}
                            productId={e._id}
                          />
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default ProductTable;
