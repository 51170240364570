import React from "react";
import { Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import Header from "../component/header/Header";
import Sidebar from "../component/sidebar/Sidebar";

const Main = ({ children }) => {
  return (
    <Flex w={"100vw"} h="100vh" bg={"#F7FAFC"} flexDirection={"column"}>
      <Header />
      <HStack w="full" h={"full"} spacing={"0"} zIndex={0} overflow={"hidden"}>
        <Sidebar />
        <VStack
          w="full"
          h={"full"}
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "10px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
              borderRadius: "8px",
            },
          }}
        >
          <Stack
            w={"94%"}
            h={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            mt={["1", "6", "6", "6", "8"]}
            // mx={["2", "8", "8", "8", "16"]}
          >
            {children}
          </Stack>
        </VStack>
      </HStack>
    </Flex>
  );
};

export default Main;
