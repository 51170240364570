import React, { useState } from "react";
import {
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineProfile,
} from "react-icons/ai";
import Cookies from "js-cookie";
import { IoMdClose } from "react-icons/io";
import axios from "../../axios";

const UserInformation = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const username = Cookies.get("username");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submitHandler = () => {
    if (
      oldPassword &&
      newPassword &&
      confirmPassword &&
      newPassword === confirmPassword
    ) {
      setIsDisable(true);
      axios
        .put(
          "/api/admin",
          {
            password: oldPassword,
            newPassword: newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result);
          setIsDisable(false);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setChangePassword(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <>
      <MenuItem onClick={() => onOpen()}>
        <HStack w={"full"} align={"center"} justify={"flex-start"}>
          <Icon w={"4"} h={"4"} as={AiOutlineProfile} />
          <Text fontSize={13} fontWeight={"normal"}>
            Миний мэдээлэл
          </Text>
        </HStack>
      </MenuItem>

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={"xs"}>
          <ModalHeader fontSize={17}>Хэрэглэгчийн мэдээлэл</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <Divider />
          <ModalBody w={"full"} pb={6}>
            <VStack w={"full"} spacing={3}>
              <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
                <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                  Нэр:
                </Text>
                <Text color={"#000"} fontSize={15} fontWeight={"medium"}>
                  {username}
                </Text>
              </HStack>
              <Center
                w={"full"}
                h={"8"}
                bg={"#ddd"}
                onClick={() => setChangePassword(!changePassword)}
                cursor={"pointer"}
              >
                {changePassword ? (
                  <Icon as={IoMdClose} />
                ) : (
                  <Text color={"#000"} fontSize={15} fontWeight={"normal"}>
                    Нууц үг солих
                  </Text>
                )}
              </Center>

              {changePassword ? (
                <VStack w={"full"}>
                  <Divider />
                  <InputGroup>
                    <Input
                      type={show ? "text" : "password"}
                      placeholder="Хуучин нууц үг"
                      rounded={"none"}
                      fontWeight={"normal"}
                      fontSize={14}
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      _hover={{
                        borderColor: "#2EAA2E",
                      }}
                      _focus={{
                        outline: "none",
                        borderColor: "#2EAA2E",
                      }}
                    />
                    <InputRightElement>
                      <Icon
                        as={show ? AiOutlineEyeInvisible : AiOutlineEye}
                        w={5}
                        h={5}
                        color="#000"
                        onClick={() => setShow(!show)}
                        cursor={"pointer"}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup>
                    <Input
                      type={show1 ? "text" : "password"}
                      placeholder="Шинэ нууц үг"
                      rounded={"none"}
                      fontWeight={"normal"}
                      fontSize={14}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      _hover={{
                        borderColor: "#2EAA2E",
                      }}
                      _focus={{
                        outline: "none",
                        borderColor: "#2EAA2E",
                      }}
                    />
                    <InputRightElement>
                      <Icon
                        as={show1 ? AiOutlineEyeInvisible : AiOutlineEye}
                        w={5}
                        h={5}
                        color="#000"
                        onClick={() => setShow1(!show1)}
                        cursor={"pointer"}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup>
                    <Input
                      type={show2 ? "text" : "password"}
                      placeholder="Нууц үгээ давтах"
                      rounded={"none"}
                      fontWeight={"normal"}
                      fontSize={14}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      _hover={{
                        borderColor: "#2EAA2E",
                      }}
                      _focus={{
                        outline: "none",
                        borderColor: "#2EAA2E",
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          if (
                            oldPassword &&
                            newPassword &&
                            confirmPassword &&
                            newPassword === confirmPassword
                          ) {
                            submitHandler();
                          }
                        }
                      }}
                    />
                    <InputRightElement>
                      <Icon
                        as={show2 ? AiOutlineEyeInvisible : AiOutlineEye}
                        w={5}
                        h={5}
                        color="#000"
                        onClick={() => setShow2(!show2)}
                        cursor={"pointer"}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Button
                    w={"full"}
                    bg={"#2EAA2E"}
                    rounded={"none"}
                    color={"#fff"}
                    fontSize={14}
                    fontWeight={"normal"}
                    _hover={{
                      bg: "#5AB54B",
                    }}
                    _focus={{
                      outline: "none",
                    }}
                    disabled={
                      oldPassword &&
                      newPassword &&
                      confirmPassword &&
                      newPassword === confirmPassword &&
                      !isDisable
                        ? false
                        : true
                    }
                    onClick={() => submitHandler()}
                  >
                    {isDisable ? <Spinner mr={2} size="sm" /> : null}
                    Хадгалах
                  </Button>
                </VStack>
              ) : null}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserInformation;
