import React, { useEffect, useState } from "react";
import { HStack, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "../../lang";
import MemberTable from "./MemberTable";
import Cookies from "js-cookie";
import { BiPlus } from "react-icons/bi";

const Member = () => {
  const [member, setMember] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const token = Cookies.get("accessToken");
  const history = useHistory();

  useEffect(() => {
    if (trigger && currentPage) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/client", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Member --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.theCount));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger, currentPage]);

  const headerData = ["#", "Нэр", "Байгууллагын регистр", "Үйлдэл"];

  const changePage = (current, pageSize) => {
    // console.log("onChange:current=", current);
    setCurrentPage(current);
    setTrigger(true);
  };

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={6}>
      <HStack w={"full"} align={"center"} justify={"space-between"}>
        <Input
          placeholder="Хэрэглэгч хайх"
          w={"xs"}
          bg={"#fff"}
          rounded={"none"}
          fontSize={13}
          fontWeight={"normal"}
          color={"#000"}
          value={member}
          onChange={(e) => setMember(e.target.value)}
          _focus={{
            outline: "none",
          }}
        />
        <HStack>
          <HStack
            bg={"#fff"}
            px={"8"}
            py={"2"}
            border={"1px"}
            borderColor={"#ddd"}
            onClick={() => history.push("/member/type")}
            cursor={"pointer"}
          >
            <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
              Хэрэглэгчийн төрөл
            </Text>
          </HStack>
          <HStack
            bg={"#fff"}
            px={"8"}
            py={"2"}
            border={"1px"}
            borderColor={"#ddd"}
            onClick={() => history.push("/member/request")}
            cursor={"pointer"}
          >
            <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
              Бүртгэлийн хүсэлт
            </Text>
          </HStack>
          <HStack
            bg={"#fff"}
            px={"8"}
            py={"2"}
            border={"1px"}
            borderColor={"#ddd"}
            onClick={() => history.push("/member/add")}
            cursor={"pointer"}
          >
            <Icon as={BiPlus} />
            <Text fontSize={13} color={"#000"} fontWeight={"medium"}>
              Хэрэглэгч нэмэх
            </Text>
          </HStack>
        </HStack>
      </HStack>
      <VStack w={"full"} pb={"6"} spacing={4}>
        <MemberTable
          data={data}
          headerData={headerData}
          isLoading={isLoading}
          setTrigger={setTrigger}
        />
        <Pagination
          // showQuickJumper
          pageSize={30}
          current={currentPage}
          onChange={changePage}
          total={totalCount}
          locale={localeInfo}
          style={{
            alignSelf: "flex-end",
          }}
        />
      </VStack>
    </VStack>
  );
};

export default Member;
