import React, { useState } from "react";
import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const PassInput = ({ title, value, setData, onKeyPress }) => {
  const [show, setShow] = useState(false);
  return (
    <VStack w={"full"} align="flex-start" justify={"flex-start"} spacing={0}>
      <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
        {title + ":"}
      </Text>
      <InputGroup>
        <Input
          placeholder="-"
          type={show ? "text" : "password"}
          w={"full"}
          // size={"sm"}
          rounded={"none"}
          fontWeight={"normal"}
          fontSize={13}
          color={"#000"}
          value={value}
          onChange={(e) => setData(e.target.value)}
          _focus={{
            outline: "none",
            borderColor: "#2EAA2E",
          }}
          _hover={{
            outline: "none",
          }}
          onKeyPress={onKeyPress}
        />
        <InputRightElement>
          <Icon
            as={show ? AiOutlineEyeInvisible : AiOutlineEye}
            w={5}
            h={5}
            color="#6A6A6A"
            onClick={() => setShow(!show)}
            cursor={"pointer"}
          />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};

export default PassInput;
