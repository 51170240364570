import React from "react";
import {
  Avatar,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
} from "@chakra-ui/react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { HiLogout } from "react-icons/hi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import Cookies from "js-cookie";
import UserInformation from "../user/UserInformation";

const Header = () => {
  const history = useHistory();
  const context = useContext(UserContext);
  const username = Cookies.get("username");

  return (
    <HStack
      zIndex={99}
      w={"full"}
      h={"14"}
      bg={"#fff"}
      shadow={"sm"}
      align={"center"}
      justify={"space-between"}
      pl={"10"}
      pr={"4"}
    >
      <Text fontWeight={"bold"}>LOGO</Text>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              variant={"ghost"}
              rightIcon={<Icon as={isOpen ? IoIosArrowUp : IoIosArrowDown} />}
              _focus={{
                outline: "none",
              }}
              _hover={{
                bg: "#fff",
              }}
              _active={{
                bg: "#fff",
              }}
            >
              <HStack>
                <Avatar size={"sm"} />
                <Text
                  fontSize={13}
                  color={"#000"}
                  fontWeight={"medium"}
                  pr={"2"}
                  textTransform={"capitalize"}
                >
                  {username}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              <UserInformation />
              <MenuItem onClick={() => history.push("/admin")}>
                <HStack w={"full"} align={"center"} justify={"flex-start"}>
                  <Icon w={"4"} h={"4"} as={MdOutlineAdminPanelSettings} />
                  <Text fontSize={13} fontWeight={"normal"}>
                    Админы жагсаалт
                  </Text>
                </HStack>
              </MenuItem>
              <MenuItem onClick={() => context.logoutHandler()}>
                <HStack w={"full"} align={"center"} justify={"flex-start"}>
                  <Icon w={"4"} h={"4"} as={HiLogout} />
                  <Text fontSize={13} fontWeight={"normal"}>
                    Гарах
                  </Text>
                </HStack>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </HStack>
  );
};

export default Header;
