import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import Cookies from "js-cookie";
import axios from "../../../axios";

const TypeTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("TypeTable -->", data);
  const [isDisable, setIsDisable] = useState(false);
  const [typeId, setTypeId] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteHandler = () => {
    if (typeId) {
      setIsDisable(true);
      axios
        .delete("/api/client/client_type", {
          data: {
            clientTypeId: typeId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          onClose();
          setTrigger(true);
          setTypeId("");
          setIsDisable(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisable(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"}>
          {headerData ? (
            <Thead>
              <Tr bg={"#F6F6F6"}>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i} bg={"#fff"}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                      color={"#000"}
                      fontWeight={"normal"}
                      fontSize={13}
                    >
                      <Text>{i + 1}</Text>
                    </Td>
                    {/* ------------ Нэр ------------ */}
                    {e.name ? (
                      <Td
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.name}
                        </Text>
                      </Td>
                    ) : null}
                    {/* ------------ Дэлгэрэнгүй ------------ */}
                    {e.description || e.description === "" ? (
                      <Td
                        w={"full"}
                        borderRight={"1px"}
                        borderRightColor={"#ECECEC"}
                        justify={"flex-start"}
                      >
                        <Text
                          fontSize={13}
                          fontWeight={"normal"}
                          color={"#000"}
                        >
                          {e.description === "" ? "-" : e.description}
                        </Text>
                      </Td>
                    ) : null}
                    <Td w={"20"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>
                        <MenuList
                          minW="24"
                          w={"max-content"}
                          p={"0"}
                          rounded={"none"}
                          shadow={"md"}
                        >
                          <MenuItem
                            color={"#ff0000"}
                            fontWeight={"normal"}
                            fontSize={13}
                            onClick={() => {
                              onOpen();
                              setTypeId(e._id);
                            }}
                          >
                            Устгах
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#2EAA2E"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent w={"sm"}>
          <ModalHeader fontSize={17}>Устгах уу?</ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalFooter>
            <Button
              colorScheme="gray"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
            >
              Үгүй
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focus={{
                outline: "none",
              }}
              onClick={deleteHandler}
              disabled={isDisable}
            >
              {isDisable ? <Spinner mr={2} size="sm" /> : null}
              Тийм
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default TypeTable;
